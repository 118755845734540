// --------------------------------------------------------------------------------------------------
// Checks if browser is supported based on if the browser supports specific CSS properties
// --------------------------------------------------------------------------------------------------
  const supportedBroswer = () => {
    let isSupported = false

    // create flex container with row-gap set
    const flex = document.createElement('div')

    if (flex) {
      flex.style.display = 'flex'
      flex.style.flexDirection = 'column'
      flex.style.rowGap = '1px'

      // create two, elements inside it
      flex.appendChild(document.createElement('div'))
      flex.appendChild(document.createElement('div'))

      // append to the DOM (needed to obtain scrollHeight)
      document.body.appendChild(flex)

      isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap

      flex.parentNode.removeChild(flex)
    }

    return isSupported
  }

// --------------------------------------------------------------------------------------------------
// Sets the `supported` class which shows the login button and screen
// While toggling the `display` property of these elements really weak precaution, it's really all
// that's needed with regard to our current user base.
// Anyone who circumventing this and logs in anyway will just be treated to a bunch of broken screens
// so there's no real damage that can be done, more just trying to enforce a useable UX.
// --------------------------------------------------------------------------------------------------
  const setSupportedBrowserStyles = () =>  {
    document.body.classList.add('supported')
  }

// --------------------------------------------------------------------------------------------------
// Export
// --------------------------------------------------------------------------------------------------
  const BrowserSupport = {
    supportedBroswer,
    setSupportedBrowserStyles
  }

  export default BrowserSupport
