// --------------------------------------------------------------------------------------------------
// Libraries
// --------------------------------------------------------------------------------------------------
  import axios from 'axios'
  import cloudinary from 'cloudinary-core'

// --------------------------------------------------------------------------------------------------
// Constants & Global Variables
// --------------------------------------------------------------------------------------------------
  // eslint-disable-next-line no-unused-vars
  const DEBUG = true
  const CLOUDINARY_CLOUD_NAME = 'huipjtihc'
  const CLOUDINARY_IMAGE_LIST = 'airport-logos.json'
  // eslint-disable-next-line no-unused-vars
  const CLOUDINARY_TRANSFORMATION_NAME  = 'airport-logo'

  let cl

  // const cloudinary = require('cloudinary').v2

// --------------------------------------------------------------------------------------------------
// Auto detects the screen pixel density
// --------------------------------------------------------------------------------------------------
  const getDPR = () => {
    let currentDPR = window.devicePixelRatio

    return (currentDPR && currentDPR >= 1 && currentDPR <= 3) ? currentDPR : 'auto'
  }


// --------------------------------------------------------------------------------------------------
// Image Transformations
// --------------------------------------------------------------------------------------------------
  const getLogos = (clImageListURL) => {
    // console.log('')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('Logos::getLogos - clImageListURL:', clImageListURL)
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('')

    const action = clImageListURL
    const method = 'get'
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }

    const axiosConfig = {
      url: action,
      method,
      headers,
    }

    // console.log('')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('Logos::getLogos - axiosConfig:', axiosConfig)
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('')

    axios(
      axiosConfig,
    )
    .then(response => {
      // if (DEBUG) {
      //   console.log('')
      //   console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      //   console.log('Logos::getLogos - response:', response)
      //   console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      //   console.log('')
      // }

      if (response?.data?.resources) {
        setLogos(response.data.resources)
      } else {
        console.warn('Logos::getLogos - SUCCESS - Missing, incomplete, or invalid response: ', response)
      }
    })
    .catch(error => {
      console.log('')
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
      console.log('Logos::getLogos - CATCH - error (request incomplete): ', error)
      console.log('Logos::getLogos - CATCH - error.response (request incomplete): ', error.response)

      if (error?.response?.data) {
        const responseData = error.response.data

        if (responseData.errors) {
          console.log('')
          console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
          console.log('Logos::getLogos - CATCH - responseData.errors: ', responseData.errors)
          console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
          console.log('')
        }

        console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        console.log('')
      }
    })
    // eslint-disable-next-line no-unused-vars
    .finally(response => {
      // if (DEBUG) {
      //   console.log('')
      //   console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      //   console.log('Logos::getLogos - FINALLY - response:', response)
      //   console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      //   console.log('')
      // }
    })
  }

  // const groupLogosByMembership = (imageList) => {
  //   // console.log('')
  //   // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  //   // console.log('Logos::groupLogosByMembership - imageList: ', imageList)
  //   // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  //   // console.log('')

  //   const groups = {}

  //   // console.log('')
  //   // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  //   // console.log('Logos::groupLogosByMembership - memberGroups: ', memberGroups)
  //   // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  //   // console.log('')

  //   memberGroups.forEach((groupName) => {
  //     // console.log('')
  //     // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  //     // console.log('Logos::groupLogosByMembership - groupName: ', groupName)
  //     // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  //     // console.log('')

  //     groups[groupName] = imageList.filter((listItem) => {
  //       let groupTag = listItem.context.custom.group

  //       // console.log('')
  //       // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  //       // console.log('Logos::groupLogosByMembership - listItem: ', listItem)
  //       // console.log('---------------------------------------')
  //       // console.log('Logos::groupLogosByMembership - groupTag: ', groupTag)
  //       // console.log('---------------------------------------')
  //       // console.log('Logos::groupLogosByMembership - (groupTag === groupName): ', (groupTag === groupName))
  //       // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  //       // console.log('')

  //       if (groupTag === groupName) {
  //         return listItem
  //       }
  //     })
  //   })

  //   // console.log('')
  //   // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  //   // console.log('Logos::groupLogosByMembership - groups (AFTER): ', groups)
  //   // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  //   // console.log('')

  //   setLogos(groups)
  // }

  const fetchCLImage = (props) => {
    // console.log('')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('Main::fetchCLImage - props', props)
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('')

    let clImage

    // if (props && props.assetPath && props.img && props.clTransformations) {
    if (props && props.assetPath && props.clTransformations) {
      clImage = cl.imageTag(props.assetPath, props.clTransformations)
    } else {
      console.warn('`prop` is invalid: ', props)
    }

    // console.log('')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('Main::fetchCLImage - clImage', clImage)
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('')

    return clImage
  }

  const setLogos = (imageList) => {
    // console.log('')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('Logos::setLogos - imageList: ', imageList)
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('')

    const logosContainer = document.querySelector('#our-customers .container')

    // console.log('')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('Logos::setLogos - logosContainer: ', logosContainer)
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('')

    if (logosContainer) {
      imageList.forEach((imageData) => {
        if (imageData) {
          const imageContainer = document.createElement('figure')

          const id      = imageData?.context?.custom?.id
          const title   = imageData?.context?.custom?.caption || imageData?.context?.custom?.title
          const alt     = imageData?.context?.custom?.alt ? imageData.context.custom.alt : `${title} logo`
          // const href    = imageData.context.custom.link ? imageData.context.custom.link : '#'
          // const width   = 175
          // const height  = 80

          imageContainer.id = id

          const clImage = fetchCLImage({
            clTransformations: { dpr: getDPR(), transformation: 'airport-logo' },
            assetPath: imageData.public_id,
          })

          if (clImage) {
            const clImageElement = clImage.toDOM()

            // clImageElement.classList.add('cld-responsive', ...classes)

            if (id)      { clImageElement.id      = `${id}-logo` }
            if (title)   { clImageElement.title   = title }
            if (alt)     { clImageElement.alt     = alt }
            // if (width)   { clImageElement.width   = width }
            // if (height)  { clImageElement.height  = height }

            imageContainer.appendChild(clImageElement)
          }

          logosContainer.appendChild(imageContainer)
        }
      })
    }
  }

// --------------------------------------------------------------------------------------------------
// Init
// --------------------------------------------------------------------------------------------------
  // eslint-disable-next-line no-unused-vars
  const init = (event = null) => {
    // console.log('')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('Logos::initLogos...')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('')

    if (cloudinary) {
      cl = cl ? cl : new cloudinary.Cloudinary({ cloud_name: CLOUDINARY_CLOUD_NAME, secure: true })

      if (cl) {
        // Because fuck IE that's why.
        if ('URL' in window) {
          const clImageListByTag = cl.url(CLOUDINARY_IMAGE_LIST, { type: 'list' })
          // console.log('')
          // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
          // console.log('Logos::initLogos - clImageListByTag: ', clImageListByTag)
          // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
          // console.log('')

          getLogos(clImageListByTag)
        } else {
          console.log('No `URL` in `window`; Browser is not supported...')
        }
      } else {
        console.log('No `cl` found...')
      }
    } else {
      console.log('No `cloudinary` found...')
    }
  }

// --------------------------------------------------------------------------------------------------
// Export
// --------------------------------------------------------------------------------------------------
  const AirportLogos = {
    init,
  }

  export default AirportLogos
