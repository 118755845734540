// --------------------------------------------------------------------------------------------------
// Libraries
// --------------------------------------------------------------------------------------------------
  import BrowserSupport from '@layouts/public/browser-support.js'
  import AirportLogos from '@layouts/public/airport-logos.js'

// --------------------------------------------------------------------------------------------------
// IE11
// IE-11 is not supported, thus, this is no longer necessary.
// https://stackoverflow.com/questions/52268886/object-doesnt-support-property-or-method-foreach-ie-11
// --------------------------------------------------------------------------------------------------
  // if (typeof NodeList.prototype.forEach !== 'function')  {
  //   NodeList.prototype.forEach = Array.prototype.forEach
  // }

// --------------------------------------------------------------------------------------------------
// ScrollTo links for main nav on desktop
// --------------------------------------------------------------------------------------------------
  const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style
  const scrollToPosition = (position = 0) => {
    // console.log('position: ', position)

    const scrollToOptions = {
      top: position,
      left: 0,
      behavior: 'smooth',
    }

    if (isSmoothScrollSupported) {
      // Native smooth scrolling
      window.scroll(scrollToOptions)
    } else {
      // Old way scrolling without effects
      window.scroll(scrollToOptions.left, scrollToOptions.top)
    }
  }

  const getScrollToElementPosition = function(anchor = null) {
    if (!anchor) { return null }

    let position
    const offset = getScrollOffset() // -120
    const element = document.querySelector(anchor)

    if (element) {
      position = element.offsetTop - offset
    }

    return position
  }

  const getScrollOffset = () => {
    const defaultOffset = 0

    const navHeader = document.querySelector('header#nav-header')
    const navHeaderComputedStyle = window.getComputedStyle(navHeader, null)
    const navHeaderPositionStyle = navHeaderComputedStyle.getPropertyValue('position')

    // console.log('navHeaderPositionStyle: ', navHeaderPositionStyle);

    if (navHeader && navHeaderPositionStyle && navHeaderPositionStyle === 'fixed') {
      return navHeader.offsetHeight
    }

    return defaultOffset
  }

  const anchorLinkClickHandler = (event) => {
    // console.log('event: ', event)
    // console.log('event.target.hash: ', event.target.hash)

    const anchor = event.target.hash
    // console.log('anchor: ', anchor)

    // If already on the home page, prevent default anchor jump
    // console.log('window.location.pathname.length: ', window.location.pathname.length)
    // if (anchorHash && window.location.pathname.length === 1) { }

    // If the current page has a corresponding anchor, prevent
    // the default and scroll to the anchor.
    // Otherwise, perform default behavior.
    if (anchor && document.querySelector(anchor)) {
      event.preventDefault()
      history.pushState(null, null, anchor)
      scrollToPosition(getScrollToElementPosition(anchor))
    }

    mobileMenuToggleHandler('close')
  }

// --------------------------------------------------------------------------------------------------
// Shows/Hides the Mobile Nav on click
// --------------------------------------------------------------------------------------------------
  const mobileMenuToggleHandler = (toggleDirective = null) => {
    const mobilMenuOpenClass = 'mobile-menu-open'
    const html = document.querySelector('html')

    if (toggleDirective) {
      if (toggleDirective === 'open') {
        html.classList.add(mobilMenuOpenClass)
      } else {
        html.classList.remove(mobilMenuOpenClass)
      }
    } else {
      if (html.classList.contains(mobilMenuOpenClass)) {
        html.classList.remove(mobilMenuOpenClass)
      } else {
        html.classList.add(mobilMenuOpenClass)
      }
    }
  }

// --------------------------------------------------------------------------------------------------
// "Ready" for Turbolinks
// --------------------------------------------------------------------------------------------------
  // document.addEventListener('turbolinks:load', function(event) { })

// --------------------------------------------------------------------------------------------------
// "Ready"
// --------------------------------------------------------------------------------------------------
  // eslint-disable-next-line no-unused-vars
  const init = (event = null) => {
    // console.log('main - init')

    // Quick & Dirty Browser Sniffing
    if (BrowserSupport.supportedBroswer()) {
      BrowserSupport.setSupportedBrowserStyles()
    } else {
      console.warn('Unsupported Browser')
    }

    if (window && window.location && window.location.hash) {
      // console.log('Detected anchor: ', window.location.hash)
      setTimeout(() => {
        // console.log('Scrolling...')
        scrollToPosition(getScrollToElementPosition(window.location.hash))
      }, 125)
    } else {
      // console.log('No anchor detected')
    }

    if (AirportLogos) {
      AirportLogos.init()
    } else {
      console.warn('Could not init AirportLogos...')
    }

    const mobileMenuIcon = document.querySelector('#mobile-menu-button #bars')
    // eslint-disable-next-line no-unused-vars
    mobileMenuIcon.addEventListener('click', (event) => {
      mobileMenuToggleHandler()
    })

    const anchorLinks = document.querySelectorAll('.anchor-link')
    anchorLinks.forEach(function(anchorLink) {
      anchorLink.addEventListener('click', anchorLinkClickHandler)
    })
  }

  // Ready / Init Listener
  if (document.readyState !== 'loading') {
    init()
  } else {
    document.addEventListener('DOMContentLoaded', init)
  }
